define("discourse/plugins/discourse-wikimedia-auth/discourse/templates/connectors/user-card-post-names/user-card-wiki-username", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{wiki-username user=this.user}}
  */
  {
    "id": "w6+sdYzz",
    "block": "[[[1,[28,[35,0],null,[[\"user\"],[[30,0,[\"user\"]]]]]]],[],false,[\"wiki-username\"]]",
    "moduleName": "discourse/plugins/discourse-wikimedia-auth/discourse/templates/connectors/user-card-post-names/user-card-wiki-username.hbs",
    "isStrictMode": false
  });
});