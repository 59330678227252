define("discourse/plugins/discourse-wikimedia-auth/discourse/templates/components/wiki-username", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.user.wiki_username}}
    <img
      src="/plugins/discourse-wikimedia-auth/images/wikimedia-logo-black.png"
    />
    <a href={{this.wikiUserUrl}}>{{this.user.wiki_username}}</a>
  {{/if}}
  */
  {
    "id": "34AJ/nzj",
    "block": "[[[41,[30,0,[\"user\",\"wiki_username\"]],[[[1,\"  \"],[10,\"img\"],[14,\"src\",\"/plugins/discourse-wikimedia-auth/images/wikimedia-logo-black.png\"],[12],[13],[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"wikiUserUrl\"]]],[12],[1,[30,0,[\"user\",\"wiki_username\"]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"img\",\"a\"]]",
    "moduleName": "discourse/plugins/discourse-wikimedia-auth/discourse/templates/components/wiki-username.hbs",
    "isStrictMode": false
  });
});