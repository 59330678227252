define("discourse/plugins/discourse-wikimedia-auth/discourse/components/wiki-username", ["exports", "@ember/component", "@ember/runloop", "@ember/service", "@ember-decorators/component", "jquery", "discourse-common/utils/decorators"], function (_exports, _component, _runloop, _service, _component2, _jquery, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WikiUsername = dt7948.c(class WikiUsername extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    didInsertElement() {
      super.didInsertElement(...arguments);
      (0, _runloop.schedule)("afterRender", () => {
        const $el = (0, _jquery.default)(this.element);
        $el.insertAfter(".full-name");
        (0, _jquery.default)(".full-name").toggleClass("add-margin", Boolean(this.user.name));
      });
    }
    wikiUserUrl(wikiUsername) {
      return `${this.siteSettings.wikimedia_auth_site}/wiki/User:${wikiUsername}`;
    }
    static #_2 = (() => dt7948.n(this.prototype, "wikiUserUrl", [(0, _decorators.default)("user.wiki_username")]))();
  }, [(0, _component2.tagName)("h2"), (0, _component2.classNameBindings)(":wiki-username")]);
  var _default = _exports.default = WikiUsername;
});